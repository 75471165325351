<template>
    <div>
        <ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in getYears"
                :key="year"
                class="nav-item"
                role="presentation">
                <a class="nav-link"
                    :class="index === 0 ? 'active' : ''"
                    :id="getYearButtonId(year)"
                    data-toggle="tab"
                    :href="getTarget(year)"
                    role="tab"
                    :aria-controls="getTabId(year)"
                    :aria-selected="index === 0 ? 'true' : 'false'">
                   {{ year }}
                </a>
            </li>
        </ul>

        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in getYears"
                :key="'content-' + year"
                class="tab-pane fade"
                :class="index === 0 ? 'show active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">

                <table class="table pricelist-table table-hover">

                    <tbody v-for="sg in priceArray">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}">
                            <td>
                                {{sg.service.name}}
                                <small>
                                   <span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                                       <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
  												 </span> 
                                      <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span> 
  												</span>
                                    
                                  <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                                      <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
  												</span>
											</small>
                                <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                                <span v-if="sg.service.mandatory"><sup>*</sup></span>
                            </td>

                            <td class="text-end">
                                <div>
                                    <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                    <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                    <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                    <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                    <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                    <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                                </div>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>

                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="isDateInRange(price.fromdate,year)">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end calc">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="hint"><sup>*</sup>{{voMsg('tpl.text.priceObligatory')}}</div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data() {
    		return {
    			priceArray: []
    		};
    	},
    	mounted() {
    		try {
    			this.priceArray = JSON.parse(this.pricelist);
    		} catch (e) {
    			console.error("Fehler beim Parsen der Preisliste", e);
    		}

    		this.$nextTick(() => {
    			const firstTab = document.querySelector('#myTab a');
    			if (firstTab) {
    				firstTab.click();
    			}
    		});
    	},
    	methods: {
    		getYearButtonId(year) {
    				return 't' + year + '-tab';
    			},
    			getTarget(year) {
    				return "#t" + year;
    			},
    			getTabId(year) {
    				return "t" + year;
    			},
    			getTabAreaLabel(year) {
    				return "t" + year + "-tab";
    			},
    			getFormattedDate: function(date) {
    				return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    			},
    			getCalculatedPrice: function(price) {
    				var rentPrice = 0;
    				var calcPrice = 0;
    				if (price > 0) {
    					rentPrice = price;
    					calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + " €";
    				}
    				return calcPrice;
    			},
    			getCalculatedMandatoryPrice: function() {
    				var rentPrice = 0;
    				var calcPrice = 0;
    				calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";

    				return calcPrice;
    			},
    			isDateInRange: function(fromdate, year) {
    				var fromYear = parseInt(fromdate.substring(0, 4));
    				//console.log('fromYear::', fromYear, "Year::", year);
    				if (fromYear == year) {
    					return true;
    				}
    				return false;
    			}
    	},
    	computed: {
    		getYears() {
    			let years = [];
    			if (!Array.isArray(this.priceArray)) return years;

    			this.priceArray.forEach(item => {
    				item.lines.forEach(line => {
    					if (line.fromdate) {
    						let year = line.fromdate.substring(0, 4);
    						if (!years.includes(year)) {
    							years.push(year);
    						}
    					}
    				});
    			});

    			return years;
    		}
    	}
    };
</script>